import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);
const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/forgetpassword",
        name: "forgetpassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/ForgerPasswordPage.vue"),
    },
    {
        path: "/video-example",
        name: "VideoExample",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/VideoExample.vue"),
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Login.vue"),
    },
    {
        path: "/register",
        name: "RegisterPage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/RegisterPage.vue"),
    },
    {
        path: "/courses",
        name: "courses",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/CoursePage.vue"),
    },
    {
        path: "/all-courses",
        name: "allCourses",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/AllCourses.vue"),
    },
    {
        path: "/category/:id",
        name: "allCourses",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        props: true,
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/CategoryCourses.vue"),
    },
    {
        path: "/all-teachers",
        name: "allTeachers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/AllTeachers.vue"),
    },
    {
        path: "/lessons",
        name: "lessons",
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Lesson.vue"),
    },
    {
        path: "/prepare-lessons",
        name: "prepareLessons",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/PrepareLesson.vue"),
    },
    {
        path: "/user-profile",
        name: "userProfile",
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/UserProfile.vue"),
    },
    {
        path: "/change-password",
        name: "changePassword",
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/ChangePassword.vue"),
    },
    {
        path: "/teacher-detail",
        name: "teacherDetail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/TeacherDetail.vue"),
    },
    {
        path: "/quiz",
        name: "quiz",
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Quiz.vue"),
    },
    {
        path: "/user-confirmation",
        name: "user-confirmation",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/UserConfirmation.vue"),
    },
    {
        path: "/favorite-menu-book",
        name: "favoriteMenuEbook",

        // meta: {
        //     requiresAuth: true
        // },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Ebook.vue"),
    },
    {
        path: "/confirm-payment/:id",
        name: "confirmPayment",

        // meta: {
        //     requiresAuth: true
        // },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/ConfirmPayment.vue"),
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.state.Auth.access_token) {
            store
                .dispatch("Auth/getUser", store.state.Auth.access_token)
                // eslint-disable-next-line no-unused-vars
                .catch((err) => {
                    if (err.response.status == 401) {
                        store.dispatch("Auth/clearToken");
                        next({
                            path: "/login",
                            query: {
                                redirect: to.fullPath
                            },
                        });
                    }
                });
        } else {
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                },
            });
        }
    }
    next();
});

export default router;