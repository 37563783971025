import Service from "../../service/index";
// import BaseService from "../../service/service";

const Category = {
  name: "Category",
  stateFactory: true,
  namespaced: true,
  state: {
    category: [],
  },
  mutations: {
    SET_CATEGORY: (state, data) => {
      state.category = data;
    },
  },
  actions: {
    getCategory({ commit }) {
      return Service.Category.onGetCategory().then((response) => {
        if (response.code === 200) {
          let response_data = response.result.category.data;
          commit("SET_CATEGORY", response_data);
          return response;
        }
      });
    },
  },
};

export default Category;
