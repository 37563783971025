import Vue from "vue";
import Vuex from "vuex";
import Courses from "./modules/CoursesModule";
import Auth from "./modules/AuthModule";
import Category from "./modules/CategoryModule";
import Lessons from "./modules/LessonsModule";
import Teachers from "./modules/TeachersModule";
import Question from "./modules/QuestionModule";
import createPersistedState from "vuex-persistedstate";
import Group from "./modules/GroupModule";
import QA from "./modules/QAModule";


Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        Courses,
        Auth,
        Category,
        Lessons,
        Teachers,
        Question,
        Group,
        QA
    },
    plugins: [createPersistedState()],
});