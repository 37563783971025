import Service from "../../service/index";
// import BaseService from "../../service/service";

const Group = {
    name: "Group",
    stateFactory: true,
    namespaced: true,
    state: {
        group: [],
    },
    mutations: {
        SET_GROUP: (state, data) => {
            state.group = data;
        },
    },
    actions: {
        getGroup({ commit }) {
            return Service.Group.onGetGroup().then((response) => {
                commit("SET_GROUP", response);
                return response;
            });
        },
    },
};

export default Group;