import Service from "../service";

const lessons = {
  onGetLessons(id) {
    return Service.get(`/lessons/${id}`);
  },
  onSaveLessonProgress(model, accessToken) {
    return Service.post(`/course/lesson-progress`, model, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
};

export default lessons;
