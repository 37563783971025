import Service from "../../service/index";

const Question = {
  name: "Question",
  stateFactory: true,
  namespaced: true,
  state: {
    question: [],
    answer: [],
    unfinishAnswer: null,
    score: [],
    allScore: [],
  },
  mutations: {
    SET_QUESTION: (state, data) => {
      state.question = data;
    },
    SET_ANSWER: (state, data) => {
      state.answer = data;
    },
    SET_UNFINISH_ANSWER: (state, data) => {
      state.unfinishAnswer = data;
    },
    SET_SCORE: (state, data) => {
      state.score = data;
    },
    SET_ALL_SCORE: (state, data) => {
      state.allScore = data;
    },
  },
  actions: {
    getQuestion({ commit }, { quizId, accessToken }) {
      commit("SET_QUESTION", []);
      commit("SET_ANSWER", []);
      commit("SET_UNFINISH_ANSWER", null);
      return Service.Question.onGetQuestion(quizId, accessToken).then(
        (response) => {
          if (response.code === 200) {
            let response_data = response.result;
            commit("SET_QUESTION", response_data.question_set);
            commit("SET_ANSWER", response_data.user_answer);
            commit("SET_UNFINISH_ANSWER", response_data.user_unfinish_answer);
            return response;
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    getQuestionNotice({ commit }, { quizId, accessToken }) {
      commit("SET_QUESTION", []);
      commit("SET_ANSWER", []);
      commit("SET_UNFINISH_ANSWER", null);
      return Service.Question.onGetQuestionNotice(quizId, accessToken);
    },
    sendAnswer({ commit }, { data, accessToken }) {
      return Service.Question.sendAnswer(data, accessToken).then((response) => {
        if (response.code === 200) {
          let response_data = response.result;
          commit("SET_SCORE", response_data);
          return response;
        }
      });
    },
    onGetUserScore({ commit }, { user_id, accessToken }) {
      return Service.Question.getUserScore(user_id, accessToken).then(
        (response) => {
          if (response.code === 200) {
            let response_data = response.result;
            commit("SET_ALL_SCORE", response_data);
            return response;
          }
        }
      );
    },
  },
};
export default Question;
