import Service from "../service";

const Teachers = {
  onTeacherList(search) {
    return Service.get(`/teachers`, {
      params: { search },
    });
  },
  onGetTeacher(id) {
    return Service.get(`/teachers/${id}`);
  },
  onGetIndexTeachers() {
    return Service.get(`/index/teachers`);
  },
  onGetAllTeacherData() {
    return Service.get(`/data/teachers`);
  },
};

export default Teachers;
