import Service from "../../service/index";
// import BaseService from "../../service/service";

const User = {
  name: "User",
  stateFactory: true,
  namespaced: true,
  state: {
    userData: [],
    userDataNRCT: [],
    user_login: null,
    access_token: null,
    token_type: null,
    username: null,
  },
  mutations: {
    SET_USER: (state, data) => {
      state.userData = data;
    },
    SET_USER_NRCT: (state, data) => {
      state.userDataNRCT = data;
    },
    SET_TOKEN(state, res) {
      state.access_token = res.access_token;
      state.token_type = res.token_type;
      state.userData = res.user;
      state.username = res.user.first_name;
      // console.log("access_token", res.access_token);
      // console.log("token_type", res.token_type);
      // console.log("users", res.user);
      // console.log("username", res.user.first_name);
    },
    CLEAR_TOKEN(state) {
      state.access_token = null;
      state.token_type = null;
      state.userData = [];
      state.username = null;
    },
    CLEAR_COURSE: (state, data) => {
      state.course = data;
    },
  },
  actions: {
    register({ commit }, data) {
      return Service.auth.onRegister(data).then((response) => {
        if (response.code === 200) {
          console.log(commit);
          // commit("SET_USER", response.result);
          return response;
        }
      });
    },
    registerWC({ commit }, data) {
      return Service.auth.onRegisterWC(data).then((response) => {
        if (response.code === 200) {
          console.log(commit);
          // commit("SET_USER", response.result);
          return response;
        }
      });
    },
    login({ commit }, data) {
      return Service.auth.onLogin(data).then((response) => {
        if (response.code === 200) {
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    loginGoogle({ commit }, data) {
      return Service.auth.onLoginGoogle({ token: data }).then((response) => {
        if (response.code === 200) {
          // console.log("response.result", response.result);
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    loginFacebook({ commit }, data) {
      return Service.auth.onLoginFacebook(data).then((response) => {
        if (response.code === 200) {
          // console.log("response.result", response.result);
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    forgotPassword({ commit }, data) {
      return Service.auth.onForgotPassword(data).then((response) => {
        if (response.code === 200) {
          return response;
        } else {
          return response.code;
        }
      });
    },
    logout({ commit }) {
      commit("CLEAR_TOKEN");
      return 200;
    },
    clearToken({ commit }) {
      commit("CLEAR_TOKEN");
    },
    // eslint-disable-next-line no-unused-vars
    userConfirmation({ commit }, code) {
      return Service.auth.userConfirmation(code).then((response) => {
        if (response.code === 200) {
          // commit("SET_USER", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    editUserProfile({ commit }, data) {
      return Service.auth.onEditProfile(data).then((response) => {
        if (response.code === 200) {
          commit("SET_USER", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    changePassword({ commit }, data) {
      return Service.auth
        .onChangePassword(data)
        .then((response) => {
          if (response.code === 200) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    getUser({ commit }, accessToken) {
      return Service.auth.getUser(accessToken);
    },
  },
};

export default User;
