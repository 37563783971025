<template>
  <div class="px-2 py-2 d-flex align-items-center">
    <div v-if="image" class="parent">
      <img class="mr-3 fluid img-panel" :src="image" alt="user_profile" />
    </div>
    <img
      v-else
      :src="noImage"
      class="mr-3 fluid rounded-circle"
      style="width: 50px; height: 50px"
      alt="user_profile"
    />
    <div class="ml-3 d-flex flex-column">
      <small> <span class="orange-text">ชื่อ</span> {{ name }}</small>
      <small>
        <span class="orange-text">หน่วยงาน</span>
        {{ department ? department : "" }}</small
      >
    </div>
  </div>
</template>

<script>
import noImage from "../assets/image/mock/no-image.png";
export default {
  data() {
    return {
      noImage: noImage,
    };
  },
  props: ["name", "image", "id","department"],
};
</script>

<style>
.img-panel {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.parent {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
